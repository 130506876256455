<!-- Yo dawg, we heard you like websites so we put a website in yo website -->
<template>
  <div>
    <LoadingBars
      v-if="!loaded && showLoadingBars"
      class="py-4"
      data-test="inception-frame"
    />
    <!-- We don't wait for loaded to display this iframe because the contents may need to be displayed to render properly, as occurs with Jotform's embeds -->
    <iframe
      v-bind="$attrs"
      ref="iframe"
      :data-whisperer-loaded="loaded"
      :class="{
        'loading': !loaded
      }"
      :src="src"
    />
  </div>
</template>
<script>
import iframeWhisperer from '@grantstreet/iframe-whisperer'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'

export default {
  emits: ['loaded', 'error', 'failed'],
  // We want to forward attributes to the iframe, not the container div (v-bind="$attrs").
  inheritAttrs: false,
  components: {
    LoadingBars,
  },
  data: () => ({
    loaded: false,
    child: null,
  }),
  props: {
    src: {
      type: String,
      required: true,
    },
    actions: {
      type: Object,
      default: () => ({}),
    },
    messageSource: {
      type: String,
      default: undefined,
    },
    allowReceiveOrigin: {
      type: Function,
      default: () => {
        return true
      },
    },
    showLoadingBars: {
      type: Boolean,
      default: true,
    },
    // This means that the child must be handling height as well or it will have
    // to wait the additional time.
    awaitHeight: {
      type: Boolean,
      default: false,
    },
    // This means that the child must be using a current version of the
    // iframeWhisperer or else the parent will interpret the lack of handshake
    // as a load failure.
    requireHandshake: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // Provide easier access to iframeWhisperer methods to outside components.
    message (...args) {
      return this.child.message(...args)
    },
    notify (...args) {
      return this.child.notify(...args)
    },
    removeEl (...args) {
      return this.child.removeEl(...args)
    },
    removeElAll (...args) {
      return this.child.removeElAll(...args)
    },
    iframeLoaded () {
      if (this.loaded) {
        return
      }
      this.$emit('loaded')
      this.loaded = true
    },
    // Error sent by child side
    handleLoadError () {
      this.$emit('error')
    },
    // Iframe failed to connect properly
    handleLoadFailure () {
      this.$emit('failed')
    },
    mount () {
      if (this.child) {
        return
      }

      this.child = new iframeWhisperer.ToChild({
        iframe: this.$refs.iframe,
        actions: {
          'iframeWhisperer.loaded': this.iframeLoaded,
          'iframeWhisperer.loadError': this.handleLoadError,
          'iframeWhisperer.loadFailure': this.handleLoadFailure,
          ...this.actions,
        },
        allowReceiveOrigin: this.allowReceiveOrigin,
        messageSource: this.messageSource,
        awaitHeight: this.awaitHeight,
        requireHandshake: this.requireHandshake,
      })

      // Similar to an EW demo page 'variation' we use this only for testing
      if (/\/child-handshake-only/i.test(this.$route.fullPath)) {
        this.child.initHandshake = () => {
          console.warn('Parent handshake blocked for testing')
        }
      }
    },
    destroy () {
      // This is important for garbage collection.
      // Note because deactivated and destroyed call this,
      // it's not unexpected if child is already null
      this.child?.destroy()
      this.child = null
      this.loaded = false
    },
  },
  mounted () {
    this.mount()
  },
  unmounted () {
    this.destroy()
  },
  activated () {
    this.mount()
  },
  deactivated () {
    this.destroy()
  },
}
</script>
<style lang="scss">

iframe {
  width: 100%;
  border: none;

  // Without this, the iframe will have a small gap underneath it (which isn't
  // ideal if we want to position it directly at the bottom of something)
  display: block;

  &.loading {
    position: absolute;
    top: 0;
    right: 99999px;
    z-index: -100000;
    visibility: hidden;
    opacity: 0;
  }
}

</style>
