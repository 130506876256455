// Returns a random item from the passed array
export const randomArrayItem = array =>
  array[Math.floor(Math.random() * array.length)]

// Returns the first key which passes the predicate function
export const findKey = (object, predicate) => {
  for (const [key, value] of Object.entries(object)) {
    if (predicate(value, key, object)) {
      return key
    }
  }
}

// Returns the "first" key from the passed object
// Remember key order is arbitrary, usually by prop assignment order
export const anyKey = object => Object.keys(object)[0]

// Returns the "first" value from the passed object
export const anyValue = object => object[anyKey(object)]

// Returns a random key from the passed object
export const randomKey = object => randomArrayItem(Object.keys(object))

// Returns a random value from the passed object
export const randomValue = object => object[randomKey(object)]

// On the subject of the delete operator. Don't use it. It's not a good idea.
// This is a much better way to remove props if you really need to do so.
// const { secret: deleted, sacred: deleted2, ...sanitized } = data
//
// 1. If you can, just set to null
// 2. If you can't, then don't use delete
// 3. If you know the keys then use the above shorthand
// 4. If the keys are dynamic, use one of these functions

// Copies an object, stripping any keys which pass predicate function. Does not
// mutate.
export const stripKeys = (object, predicate) => Object.entries(object).reduce((copy, [key, value]) => {
  // Propagate keys which pass predicate
  if (!predicate(key)) {
    copy[key] = value
  }
  return copy
}, {})

// Copies an object, stripping any keys present in a list. Does not mutate.
export const redactProps = (object, keys, message) => {
  const predicate = Array.isArray(keys)
    ? key => keys.includes(key)
    : key => key === keys

  return Object.entries(object).reduce((redacted, [key, value]) => {
    if (!predicate(key)) {
      redacted[key] = value
    }
    else if (message) {
      redacted[key] = message
    }
    return redacted
  }, {})
}

/**
 * @function mapByKey
 * Maps an array of objects by key.
 * @param  {Array} array - An array of objects.
 * @param  {string} key  - The key to map to.
 * @return {object}      - An object containing array's elements mapped to key.
 */
export const mapByKey = (array, key) => array.reduce((map, object) => {
  if (object && object[key]) {
    map[object[key]] = object
  }
  return map
}, {})

/**
 * @function getCircularReplacer Use as a custom replacer for JSON.stringify()
 * to prevent exceptions from circular references. Replaces circular references
 * in output with "[Circular]".
 * @return {object} - A replacer for JSON.stringify which prevents errors from
 * circular references.
 */
export const getCircularReplacer = () => {
  const seen = new WeakSet()
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return '[Circular]'
      }
      seen.add(value)
    }
    return value
  }
}
